import React, { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@app/types"
import { SapCrmCampaign } from "@app/types/root-state/bonusCampaign"
import { showPersonalCampaignsPopup } from "@app/store/app/appActions"
import { userHelpers } from "@ng-mw/shared-react-components/user"

// Components
import Tips from "@modules/shared/Tips"
import Button from "@ng-mw/reol/components/Button"
import { getIconFromStringName } from "@app/helpers"
import { eventHasHappenedRecently, triggerEventNow } from "@modules/shared/MessageAfterEvent"
import MenyLocalStorage from "@app/MenyLocalStorage"
import Container from "@modules/shared/Container"

const PersonalCampaigns = () => {
    const dispatch = useDispatch<any>()
    const { data } = useSelector((state: RootState) => state.bonusCampaign)
    const { isOrganization } = useSelector((state: RootState) => state.user.data.household)
    const [index, setIndex] = useState(0)
    const [isVisible, setIsVisible] = useState(true)
    const {
        filteredCampaigns,
        campaign,
        localStorageKey,
    } = useMemo(() => {
        let filteredCampaigns = []
        if (Array.isArray(data) && data.length) {
            filteredCampaigns = userHelpers.getActivePersonalCampaigns(data)
        }
        const campaign: SapCrmCampaign = filteredCampaigns[index]
        const localStorageKey = `${MenyLocalStorage.UserSeenPersonalCampaign}`

        return {
            filteredCampaigns,
            campaign,
            localStorageKey
        }

    }, [data, index])

    if (!campaign || isOrganization) return null

    const handleButtonClick = () => {
        dispatch(showPersonalCampaignsPopup())
    }

    const handleClose = () => {
        const maxIndex = filteredCampaigns.length

        if (maxIndex > index + 1) {
            setIndex(index + 1)
        } else {
            triggerEventNow(localStorageKey)
            setIsVisible(false)
        }
    }

    if (!campaign || !isVisible || eventHasHappenedRecently(localStorageKey, 1440)) return null

    const icon = getIconFromStringName(campaign.type)

    return (
        <Container>
            <Tips
                text={campaign.langBeskrivelse}
                onClose={handleClose}
                titleFont="mulish"
                illustration={icon}
                illustrationLeftOffset="0"
                className="loyalty-club-section-game"
                background="light-green"
                size={{ width: "81px", height: "81px" }}
            >
                {filteredCampaigns?.length > 0 ?
                    <Button
                        onClick={handleButtonClick}
                        className="loyalty-club-section-game__button"
                        isCancel
                        text="Se alle fordelene dine"
                        size="small"
                    />
                    : null
                }
            </Tips>
        </Container>
    )
}
export default PersonalCampaigns
