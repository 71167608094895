
import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { hidePersonalCampaignsPopup } from "@app/store/app/appActions"
import { RootState } from "@app/types"

// Components
import Button from "@ng-mw/reol/components/Button"
import Popup from "@modules/popup/Popup"
import Container from "@modules/shared/Container"
import { PersonalCampaignList, userHelpers } from "@ng-mw/shared-react-components/user"

export const PersonalCampaignsPopup = () => {
    const dispatch = useDispatch<any>()
    const isOpen = useSelector((state: RootState) => state.app.personalCampaignsPopup.visible)
    const { data } = useSelector((state: RootState) => state.bonusCampaign)
    const { isOrganization } = useSelector((state: RootState) => state.user.data.household)

    const filteredCampaigns = useMemo(() => {
        let filteredCampaigns = []
        if (Array.isArray(data) && data.length) {
            filteredCampaigns = userHelpers.getActivePersonalCampaigns(data)
        }

        return filteredCampaigns

    }, [data])

    const handleClose = () => {
        dispatch(hidePersonalCampaignsPopup())
    }

    if (!filteredCampaigns.length || isOrganization) return null

    return (
        <Popup
            isOpen={isOpen}
            title="Fordelene dine"
            buttons={
                <>
                    <Button
                        text="Lukk"
                        onClick={handleClose}
                    />
                </>
            }
        >
            <Container>
                <PersonalCampaignList campaigns={filteredCampaigns} />
            </Container>
        </Popup>
    )
}

export default PersonalCampaignsPopup
